<template>
  <nav class="text-white text-base font-semibold pt-3">
    <router-link to="/" class="flex items-center active-nav-link text-white py-2 pl-6 nav-item">
      Projects
    </router-link>
    <router-link to="/accounts" class="flex items-center active-nav-link text-white py-2 pl-6 nav-item">
      Accounts
    </router-link>
  </nav>
</template>

<script>
export default {
  name: "DefaultLayout",
}
</script>